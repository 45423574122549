import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { ConfirmationDialog, Loader } from '@netiva/classifieds-ui';

import { PageTitle } from '@/components/global';
import { routes } from '@/lib/routes';

import { Layout, NavigationBar, Preview, PriceSummary, PrintPriceSummary, Step } from './components';
import { useAd, useAdPublishing } from './hooks';
import { AdProvider } from './providers';
import { getFilterStatus } from '../MyAds/utils';

const AdPageInner: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    steps,
    validationStatus,
    gotoNextStep,
    gotoPrevStep,
    gotoStep,
    currentStep,
    currentStepIndex,
    isNew,
    save,
    saveAndPublish,
    isLoading,
    isSubmitting,
    isPrintAdvert,
    hasChanges,
    canSave,
    dataObject,
  } = useAd();

  const canGoBack =
    currentStep?.prevNavigationType === 'Enabled' || (isNew && currentStep?.prevNavigationType === 'Unsaved');

  const { requiresPublication } = useAdPublishing();

  const [tosChecked, setTosChecked] = useState(false);
  const toast = useToast();
  const {
    isOpen: isCloseConfirmationOpen,
    onClose: closeCloseConfirmation,
    onOpen: openCloseConfirmation,
  } = useDisclosure();

  const handleSubmit = () => {
    if (!tosChecked) {
      toast({
        status: 'error',
        description: t('ad.steps.review.submitTosError'),
        isClosable: true,
      });
      return;
    }
    saveAndPublish();
  };

  const handleClose = () => {
    if (hasChanges) {
      openCloseConfirmation();
    } else {
      navigate(routes.myAds({ status: getFilterStatus(dataObject?.status) }));
    }
  };
  const handleCloseCancel = () => {
    closeCloseConfirmation();
    navigate(routes.myAds({ status: getFilterStatus(dataObject?.status) }));
  };
  const handleCloseConfirm = () => {
    save().then(closeCloseConfirmation);
  };

  return (
    <Layout onClose={handleClose}>
      <PageTitle titleRes={isNew ? 'ad.create.title' : 'ad.edit.title'} />
      <Loader isLoading={!currentStep || isLoading} showOverlay />
      {!!currentStep && (
        <>
          {!currentStep.isHidden && (
            <NavigationBar
              steps={steps}
              currentStepIndex={currentStepIndex}
              gotoStep={gotoStep}
              validationStatus={validationStatus}
            />
          )}
          <Heading as="h1" size="md" mb={6}>
            {/* @ts-expect-error dynamic translation */}
            {t(currentStep.titleResourceKey)}
          </Heading>
          {currentStep.introResourceKey && (
            /* @ts-expect-error dynamic translation */
            <Text mb={6} dangerouslySetInnerHTML={{ __html: t(currentStep.introResourceKey) || '' }} />
          )}
          <Grid
            templateColumns={{
              base: '1fr',
              md: currentStep.showPreview || currentStep.showPriceSummary ? '2fr minmax(min-content, 1.1fr)' : '1fr',
            }}
            gap={8}
          >
            <GridItem>
              <Step step={currentStep} />
              {currentStep.nextNavigationType === 'Publish' && requiresPublication && (
                <Checkbox isChecked={tosChecked} onChange={(e) => setTosChecked(e.target.checked)} mt={4} isRequired>
                  <span dangerouslySetInnerHTML={{ __html: t('ad.steps.review.tosText') }}></span>
                </Checkbox>
              )}
            </GridItem>
            {(currentStep.showPreview || currentStep.showPriceSummary) && (
              <GridItem>
                <Divider display={{ md: 'none' }} mb={4} />
                {isPrintAdvert && <Preview />}
                {currentStep.showPriceSummary && (
                  <>
                    {isPrintAdvert ? (
                      <PrintPriceSummary showMultipleIssues={currentStep.showPriceSummaryTotal} />
                    ) : (
                      <PriceSummary position="sticky" top={4} />
                    )}
                  </>
                )}
              </GridItem>
            )}
          </Grid>
          <Divider my={4} />
          <Flex justify="flex-end" align="center" gap={4} wrap="wrap">
            {canGoBack && (
              <Button colorScheme="neutral" onClick={gotoPrevStep} mr="auto">
                {t('ui.buttons.previous')}
              </Button>
            )}
            {canSave && (
              <Button colorScheme="neutral" onClick={handleCloseConfirm} disabled={isLoading} isLoading={isSubmitting}>
                {isNew ? t('ad.saveAsDraft') : t('ad.saveAndClose')}
              </Button>
            )}
            {currentStep.nextNavigationType === 'Enabled' && (
              <Button onClick={() => gotoNextStep()} isLoading={isLoading} disabled={isLoading}>
                {t('ui.buttons.next')}
              </Button>
            )}
            {currentStep.nextNavigationType === 'Publish' && requiresPublication && (
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                isLoading={isSubmitting}
                colorScheme={tosChecked ? undefined : 'neutral'}
              >
                {t('ad.steps.review.submit')}
              </Button>
            )}
          </Flex>
        </>
      )}

      <ConfirmationDialog
        isOpen={isCloseConfirmationOpen}
        onCancel={handleCloseCancel}
        onConfirm={handleCloseConfirm}
        title={t('ad.closeConfirmation.title')}
        message={t('ad.closeConfirmation.message')}
        cancelText={t('ui.buttons.no')}
        okText={t('ui.buttons.yes')}
      />
    </Layout>
  );
};

export const AdPage: FC = memo(function AdPage() {
  return (
    <AdProvider>
      <AdPageInner />
    </AdProvider>
  );
});
