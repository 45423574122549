import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BoxProps, Link, List, ListItem, SkeletonText, Text, VStack, useMultiStyleConfig } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { publicApi } from '@netiva/classifieds-api';

import { RouterLink } from '@/components/global';
import { routes } from '@/lib/routes';
import { CommonSteps } from '@/pages/Ad/constants';
import { useAppSelector } from '@/store';
import { SidebarComponentName } from '@/theme';
import { AccountSelectionButton } from './AccountSelectionButton';
import { LanguageSwitcher } from './LanguageSwitcher';

export const Sidebar: FC<BoxProps> = ({ ...rest }) => {
  const { i18n, t } = useTranslation();
  const styles = useMultiStyleConfig(SidebarComponentName);
  const { account, hasMemberContract, invitations } = useAppSelector((state) => state.account);
  const { platforms } = useAppSelector((state) => state.global);
  const { isLoading: isLoadingPlatforms } = publicApi.useGetConfigurationPlatforms({});

  const manualText = t('ui.navigation.manual');

  return (
    <>
      <VStack sx={styles.container} spacing={8} {...rest}>
        <Box sx={styles.section}>
          <List sx={styles.list}>
            <ListItem sx={styles.listItem}>
              <RouterLink sx={styles.listItemLink} to={routes.home()}>
                {t('ui.navigation.home')}
              </RouterLink>
            </ListItem>
          </List>
        </Box>

        {account && (
          <Box sx={styles.section}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              {t('ui.navigation.section.ads')}
            </Text>
            <List sx={styles.list}>
              <ListItem sx={styles.listItem}>
                <RouterLink sx={styles.listItemLink} to={routes.myAds()}>
                  {t('ui.navigation.myads')}
                </RouterLink>
              </ListItem>
              <ListItem sx={styles.listItem}>
                <RouterLink sx={styles.listItemLink} to={routes.ad(0, CommonSteps[0].key)}>
                  {t('ui.navigation.newad')}
                </RouterLink>
              </ListItem>
              {manualText && (
                <Link
                  href="https://www.tierwelt.ch/kleinanzeigen/anleitung-zur-erfassung-von-kleinanzeigen"
                  target="_blank"
                  rel="noreferrer"
                  display="block"
                  marginTop={6}
                >
                  <ExternalLinkIcon marginRight={1} />
                  {manualText}
                </Link>
              )}
            </List>
          </Box>
        )}

        <Box sx={styles.section}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {t('ui.navigation.section.account')}
          </Text>
          <List sx={styles.list}>
            <ListItem sx={styles.listItem}>
              <RouterLink sx={styles.listItemLink} to={routes.account()}>
                {t('ui.navigation.account')}
              </RouterLink>
            </ListItem>
            {account && (
              <>
                <ListItem sx={styles.listItem}>
                  <RouterLink sx={styles.listItemLink} to={routes.addresses()}>
                    {t('ui.navigation.addresses')}
                  </RouterLink>
                </ListItem>
                {hasMemberContract && (
                  <ListItem sx={styles.listItem}>
                    <RouterLink sx={styles.listItemLink} to={routes.products()}>
                      {t('ui.navigation.products')}
                    </RouterLink>
                  </ListItem>
                )}
                <ListItem sx={styles.listItem}>
                  <RouterLink sx={styles.listItemLink} to={routes.logins()}>
                    {t('ui.navigation.logins')}
                  </RouterLink>
                </ListItem>
              </>
            )}
            {invitations.length > 0 && (
              <ListItem sx={styles.listItem}>
                <RouterLink sx={styles.listItemLink} to={routes.invitations()}>
                  {t('ui.navigation.invitations')}
                </RouterLink>
              </ListItem>
            )}
          </List>
        </Box>

        <Box sx={styles.section}>
          <Text fontSize="lg" fontWeight="bold" mb={2}>
            {t('ui.navigation.section.platforms')}
          </Text>
          <List sx={styles.list}>
            <SkeletonText isLoaded={!isLoadingPlatforms} skeletonHeight={10}>
              {platforms.map((platform) => (
                <ListItem key={platform.name} sx={styles.listItem}>
                  <Link href={`${platform.publicBaseUrl}/${i18n.language}`} target="_blank" sx={styles.listItemLink}>
                    <ExternalLinkIcon marginRight={1} />
                    {/* @ts-expect-error dynamic translation */}
                    {t(`platforms.${platform.name.toLowerCase()}`)}
                  </Link>
                </ListItem>
              ))}
            </SkeletonText>
          </List>
        </Box>

        <AccountSelectionButton
          type="button"
          colorScheme="gray"
          width="full"
          mb={8}
          display={{ base: 'block', md: 'none' }}
        />

        <LanguageSwitcher display={{ base: 'block', md: 'none' }} />
      </VStack>
    </>
  );
};
