import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CardHeader, chakra, Divider, Flex, Icon, Image, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { FaCreditCard, FaFileInvoice } from 'react-icons/fa';
import { HiOutlineDocumentText } from 'react-icons/hi';
import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListTerm,
  Loader,
  SelectableCard,
} from '@netiva/classifieds-ui';

import { useCurrencyFormatter } from '@/hooks';
import { NoPaymentProvider } from '@/pages/Ad/constants';
import { useAd, useAdAddresses, useAdAttributes, useAdPublishing } from '@/pages/Ad/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { AddressDisplay, AttributeDisplay } from './components';

const Section = chakra(Box, {
  baseStyle: {
    marginBottom: 4,
  },
});

const SectionTitle = chakra(Text, {
  baseStyle: {
    fontSize: 'md',
    fontWeight: 'bold',
    marginBottom: 4,
  },
});

const PaymentProviderCardHeader = chakra(CardHeader, {
  baseStyle: {
    p: 1,
    display: 'flex',
    flexDir: 'column',
    alignItems: 'center',
    gap: 2,
    minWidth: '150px',
  },
});

export const Review: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const formatter = useCurrencyFormatter();

  const { formData, images, documents, paymentProvider } = useAppSelector((state) => state.ad);
  const { attributes, isLoading: isLoadingAttributes } = useAdAttributes();
  const {
    activePublication,
    selectedContract,
    selectedProduct,
    selectedExtensionProducts,
    requiresPublication,
    paymentProviders,
  } = useAdPublishing();
  const { isLoading: isLoadingAddresses, /*billingAddress,*/ contactAddress, locationAddress } = useAdAddresses();

  const { uploadedFileData } = useAd();

  const setPaymentProvider = useCallback(
    (name: string) => {
      dispatch(adActions.setPaymentProvider(name));
    },
    [dispatch]
  );

  // preselect payment provider
  useEffect(() => {
    if (paymentProviders.length && !paymentProvider) {
      setPaymentProvider(paymentProviders[0]);
    }
  }, [paymentProvider, paymentProviders, setPaymentProvider]);

  return (
    <>
      <Section>
        <SectionTitle>{t('ad.steps.review.details')}</SectionTitle>
        <DescriptionList position="relative">
          <Loader isLoading={isLoadingAttributes} />
          {attributes.map((attribute) => {
            const value = formData[attribute.id];
            return value && <AttributeDisplay key={attribute.id} attribute={attribute} value={value} />;
          })}
        </DescriptionList>
      </Section>
      <Divider my={4} orientation="horizontal" />
      {!!images.length && (
        <>
          <Section>
            <SectionTitle>{t('ad.steps.review.images')}</SectionTitle>
            <Flex gap={4} wrap="wrap">
              {images.map((img) => {
                const src = img.url || uploadedFileData.find((fd) => fd.fileName === img.fileName)?.dataUrl || '';
                return <Image key={img.id} src={src} alt={img.fileName} maxWidth={['40%', '120px']} maxHeight="80px" />;
              })}
            </Flex>
          </Section>
          <Divider my={4} orientation="horizontal" />
        </>
      )}
      {!!documents.length && (
        <>
          <Section>
            <SectionTitle>{t('ad.steps.review.documents')}</SectionTitle>
            <List>
              {documents.map((doc) => (
                <ListItem key={doc.id}>
                  <ListIcon as={HiOutlineDocumentText} />
                  {doc.fileName}
                </ListItem>
              ))}
            </List>
          </Section>
          <Divider my={4} orientation="horizontal" />
        </>
      )}
      <Section>
        <SectionTitle>{t('ad.steps.review.addresses')}</SectionTitle>
        <DescriptionList position="relative">
          <Loader isLoading={isLoadingAddresses} />
          <AddressDisplay type="contact" address={contactAddress} />
          <AddressDisplay type="location" address={locationAddress} />
          {/* TODO make editable */}
          {/* <AddressDisplay type="billing" address={billingAddress} /> */}
        </DescriptionList>
      </Section>
      <Divider my={4} orientation="horizontal" />
      <Section>
        <SectionTitle>
          {requiresPublication ? t('ad.steps.review.publishing') : t('ad.steps.publishing.currentPublication')}
        </SectionTitle>
        <DescriptionList>
          <DescriptionListTerm>{t('ad.steps.review.product')}</DescriptionListTerm>
          {activePublication ? (
            <>
              {<DescriptionListItem>{activePublication.contract.product.name}</DescriptionListItem>}
              {activePublication.contract.activeExtensions.map((ext) => (
                <DescriptionListItem key={ext.id}>{ext.product.name}</DescriptionListItem>
              ))}
            </>
          ) : (
            selectedProduct && (
              <>
                {selectedProduct.name}
                {!selectedContract && <> ({formatter.format(selectedProduct.price)})</>}
              </>
            )
          )}
          {selectedContract && (
            <DescriptionListItem>
              {selectedContract.product.name} ({selectedContract.quantity - selectedContract.available} /{' '}
              {selectedContract.quantity})
            </DescriptionListItem>
          )}
          {selectedExtensionProducts.map((ep) => (
            <DescriptionListItem key={ep.id}>
              {ep.name} - ({formatter.format(ep.price)})
            </DescriptionListItem>
          ))}
        </DescriptionList>
      </Section>
      <Divider my={4} orientation="horizontal" />
      {requiresPublication && !!paymentProviders.length && (
        <Section>
          <SectionTitle>
            {t('ad.steps.review.payment.title')}
            <chakra.span color="red" ml={1}>
              *
            </chakra.span>
          </SectionTitle>
          <Flex gap={8}>
            {paymentProviders.map((p) => (
              <SelectableCard
                key={p}
                isSelected={p.toLowerCase() === paymentProvider?.toLowerCase()}
                onClick={() => setPaymentProvider(p)}
              >
                <PaymentProviderCardHeader>
                  <Icon as={FaCreditCard} boxSize={12} />
                  {/* @ts-expect-error dynamic translation */}
                  {t(`ad.steps.review.payment.providers.${p.toLowerCase()}`)}
                </PaymentProviderCardHeader>
              </SelectableCard>
            ))}
            <SelectableCard
              isSelected={paymentProvider?.toLowerCase() === NoPaymentProvider.toLowerCase()}
              onClick={() => setPaymentProvider(NoPaymentProvider)}
            >
              <PaymentProviderCardHeader>
                <Icon as={FaFileInvoice} boxSize={12} />
                {t('ad.steps.review.payment.providers.none')}
              </PaymentProviderCardHeader>
            </SelectableCard>
          </Flex>
        </Section>
      )}
    </>
  );
};
