import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex } from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { Alert, AlertData, Loader } from '@netiva/classifieds-ui';
import { skipToken } from '@reduxjs/toolkit/query';

import { routes } from '@/lib/routes';
import { useAdNavigation, useAdParams } from '@/pages/Ad/hooks';
import { AdComponentType } from '@/pages/Ad/types';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { CommonStepKeys } from '@/pages/Ad/constants';

type CallbackState = '' | 'success' | 'cancelled' | 'failed';

export const Payment: AdComponentType = () => {
  const { t } = useTranslation();
  const { dataObjectId, stepId: orderId } = useAdParams();
  const navigate = useNavigate();
  const { gotoStep } = useAdNavigation();
  const dispatch = useAppDispatch();
  const { paymentProvider, selectedContractId, selectedProductId } = useAppSelector((state) => state.ad);

  const [searchParams] = useSearchParams();
  // if this parameter is set that means the user was redirected back from the payment provider
  const callbackState = useMemo<CallbackState>(() => {
    const stateParam = searchParams.get('state')?.toLowerCase() as CallbackState;
    switch (stateParam) {
      case 'success':
      case 'cancelled':
      case 'failed':
        return stateParam;
      default:
        return '';
    }
  }, [searchParams]);

  const {
    data: statusResponse,
    isLoading: isLoadingStatus,
    refetch: refetchStatus,
  } = accountApi.useGetPaymentStatus(
    {
      orderId,
    },
    { skip: !orderId }
  );
  const [createPayment, { isLoading: isCreating }] = accountApi.useCreatePayment();
  const { data: order, isLoading: isLoadingOrder } = accountApi.useGetOrder(orderId ? { id: orderId } : skipToken);

  // restore selection from order
  useEffect(() => {
    if (order && !selectedContractId && !selectedProductId) {
      dispatch(adActions.setSelectedContractId(order.contractId || undefined));
      dispatch(adActions.setSelectedProductId(order.mainProductId || undefined));
      dispatch(
        adActions.setSelectedExtensionProductIds(
          order.items.map((i) => i.productId).filter((id) => id !== order.mainProductId)
        )
      );
    }
  }, [dispatch, order, selectedContractId, selectedProductId]);

  const handleCreatePayment = useCallback(async () => {
    if (!orderId) {
      return;
    }
    const response = await createPayment({
      accountPaymentsCreatePaymentRequest: {
        orderId,
        returnUrl: window.location.href,
        provider: paymentProvider,
      },
    }).unwrap();
    if (response.success && response.redirectUrl) {
      window.location.href = response.redirectUrl;
    }
  }, [createPayment, orderId, paymentProvider]);

  // create payment
  useEffect(() => {
    if (!statusResponse || callbackState || !dataObjectId) {
      return;
    }

    if (!statusResponse.status) {
      handleCreatePayment();
      return;
    } else if (statusResponse.status === 'Created' && statusResponse.redirectUrl) {
      window.location.href = statusResponse.redirectUrl;
      return;
    }
  }, [callbackState, dataObjectId, handleCreatePayment, statusResponse]);

  // update status polling
  useEffect(() => {
    let mounted = true;
    let timeout: NodeJS.Timeout;

    const refreshStatus = () => {
      timeout = setTimeout(async () => {
        if (mounted && statusResponse?.status === 'Created' && callbackState !== 'cancelled') {
          await refetchStatus();
          refreshStatus();
        }
      }, 2000);
    };

    refreshStatus();

    return () => {
      clearTimeout(timeout);
      mounted = false;
    };
  }, [callbackState, refetchStatus, statusResponse?.status]);

  // handle cancellation
  useEffect(() => {
    if (callbackState === 'cancelled' && order) {
      gotoStep(CommonStepKeys.review, true);
    }
  }, [callbackState, gotoStep, order]);

  const handleReturnToList = () => {
    navigate(routes.myAds());
  };

  const alert = useMemo<AlertData>(() => {
    switch (statusResponse?.status) {
      case 'Complete':
        return { status: 'success', description: t('ad.steps.payment.status.complete') };
      case 'Failed':
        return { status: 'error', description: t('ad.steps.payment.status.failed') };
      default:
        if (callbackState) {
          return { status: 'info', description: t('ad.steps.payment.status.waiting') };
        }
        return { status: 'info', description: t('ad.steps.payment.status.created') };
    }
  }, [callbackState, statusResponse?.status, t]);

  const isLoading = isLoadingOrder || isLoadingStatus || isCreating || !statusResponse;

  return (
    <Box position="relative">
      <Loader isLoading={isLoading} />

      <Alert alert={alert} />

      <Flex marginTop={8} justify="center">
        {statusResponse?.status === 'Complete' && (
          <Button onClick={handleReturnToList}>{t('ad.steps.payment.button.backToList')}</Button>
        )}
        {(callbackState === 'cancelled' ||
          statusResponse?.status === 'Cancelled' ||
          statusResponse?.status === 'Failed') && (
          <Button onClick={handleCreatePayment}>{t('ad.steps.payment.button.retry')}</Button>
        )}
      </Flex>
    </Box>
  );
};
