import { BaseUrl } from '@/environment';

export const enforceBaseUrl = () => {
  if (BaseUrl && !window.location.href.startsWith(BaseUrl)) {
    window.location.href = BaseUrl;
  }
};

export const getIdOrFileName = (file: { id?: number; fileName?: string }) => {
  if ('id' in file) {
    return `${file.id || file.fileName}`;
  }
  return file.fileName || '';
};
