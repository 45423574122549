import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CommonSteps } from '../constants';

export const useAdParams = () => {
  // parse request params
  const { id: idParam, step: stepParam, stepId: stepIdParam } = useParams();
  const dataObjectId = useMemo(() => parseInt(idParam || '0'), [idParam]);
  const currentStepKey = useMemo(() => stepParam || CommonSteps[0].key, [stepParam]);
  const stepId = useMemo(() => (stepIdParam ? parseInt(stepIdParam) : undefined), [stepIdParam]);
  const isNew = dataObjectId === 0;

  return {
    dataObjectId,
    currentStepKey,
    stepId,
    isNew,
  };
};
