import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Heading, Wrap } from '@chakra-ui/react';
import { accountApi } from '@netiva/classifieds-api';
import { Loader, SelectableTag } from '@netiva/classifieds-ui';

import { useDate } from '@/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import { adActions } from '@/store/ad';
import { useAdParams } from '@/pages/Ad/hooks';

export const IssueDateSelector: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { bookedIssueDates, selectedIssueDates } = useAppSelector((state) => state.ad);
  const { formatDate } = useDate();

  const { dataObjectId } = useAdParams();
  const { data: issueDatesData, isLoading } = accountApi.useGetPrintAdvertIssueDates({
    dataObjectId: dataObjectId || undefined,
  });
  const issueDates = useMemo(() => issueDatesData?.dates || [], [issueDatesData?.dates]);

  const isActive = (issueDate: string) => {
    return selectedIssueDates.includes(issueDate);
  };

  const handleClick = (issueDate: string) => {
    const newIssueDates = isActive(issueDate)
      ? selectedIssueDates.filter((item) => item !== issueDate)
      : [...selectedIssueDates, issueDate];
    dispatch(adActions.setSelectedIssueDates(newIssueDates));
  };

  if (!issueDates) {
    return null;
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Wrap spacing={3}>
        {issueDates.map((issueDate) => (
          <SelectableTag key={issueDate} onClick={() => handleClick(issueDate)} isSelected={isActive(issueDate)}>
            {formatDate(issueDate)}
          </SelectableTag>
        ))}
      </Wrap>
      {!!bookedIssueDates.length && (
        <>
          <Divider my={4} />
          <Heading as="h2" size="sm" mb={2}>
            {t('ad.steps.issueDates.pastDates')}
          </Heading>
          <Wrap spacing={3}>
            {bookedIssueDates.map((issueDate) => (
              <SelectableTag key={issueDate} disabled>
                {formatDate(issueDate)}
              </SelectableTag>
            ))}
          </Wrap>
        </>
      )}
    </>
  );
};
