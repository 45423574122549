import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  StackDivider,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { AddressRec, accountApi } from '@netiva/classifieds-api';
import { Loader } from '@netiva/classifieds-ui';
import { FaRegAddressCard } from 'react-icons/fa';
import { FiEdit, FiFileText, FiPlusSquare, FiUser, FiUserCheck, FiUsers } from 'react-icons/fi';

import { routes } from '@/lib/routes';
import { useAppSelector } from '@/store';
import { EditAddressModal } from '../Addresses/components';
import { CommonSteps } from '../Ad/constants';
import { HomeCard, HomeCardContainer, HomeSection } from './components';

export const HomePage: FC = memo(function HomePage() {
  const { t } = useTranslation();
  const {
    isLoaded: isAccountLoaded,
    hasMemberContract,
    hasValidAccount,
    invitations,
  } = useAppSelector((state) => state.account);
  const [getLoginInfo, { isLoading: isLoadingLoginInfo }] = accountApi.useGetLoginInfoLazy();
  const [newAccountAddress, setNewAccountAddress] = useState<AddressRec>();

  const addressModalDisclosure = useDisclosure();

  const handleCreateAccount = async () => {
    try {
      const loginInfo = await getLoginInfo({}).unwrap();
      if (loginInfo) {
        setNewAccountAddress({
          id: 0,
          type: 'Account',
          city: loginInfo.city || '',
          countryIso2: loginInfo.countryCode || '',
          email: loginInfo.email,
          firstname: loginInfo.firstName,
          gender: 'Unknown',
          lastname: loginInfo.lastName,
          street: loginInfo.streetAddress || '',
          zip: loginInfo.zipCode || '',
        });
      }
    } catch (err) {
      console.error(err);
    }
    addressModalDisclosure.onOpen();
  };

  return (
    <>
      <Loader isLoading={!isAccountLoaded} showOverlay />
      <VStack align="stretch" divider={<StackDivider />} spacing={8}>
        <HomeSection title={t('ui.navigation.section.ads')}>
          {hasValidAccount && (
            <HomeCardContainer>
              <HomeCard header={t('ui.navigation.newad')} link={routes.ad(0, CommonSteps[0].key)} icon={FiPlusSquare} />
              <HomeCard header={t('ui.navigation.myads')} link={routes.myAds()} icon={FiEdit} />
            </HomeCardContainer>
          )}
          {!hasValidAccount && isAccountLoaded && (
            <Flex justify="center">
              <Alert status="info" width="auto" alignItems="flex-start" padding={8}>
                <AlertIcon />
                <AlertDescription width="full">
                  <VStack>
                    <Text>{t('home.ads.additionalInfoRequired.message')}</Text>
                    <Button
                      type="button"
                      colorScheme="secondary"
                      onClick={handleCreateAccount}
                      isLoading={isLoadingLoginInfo}
                      isDisabled={isLoadingLoginInfo}
                    >
                      {t('home.ads.additionalInfoRequired.button')}
                    </Button>
                  </VStack>
                </AlertDescription>
              </Alert>
            </Flex>
          )}
        </HomeSection>

        <HomeSection title={t('ui.navigation.section.account')}>
          <HomeCardContainer>
            <HomeCard header={t('ui.navigation.account')} link={routes.account()} icon={FiUser} />
            {hasValidAccount && (
              <>
                <HomeCard header={t('ui.navigation.addresses')} link={routes.addresses()} icon={FaRegAddressCard} />
                {hasMemberContract && (
                  <HomeCard header={t('ui.navigation.products')} link={routes.products()} icon={FiFileText} />
                )}
                <HomeCard header={t('ui.navigation.logins')} link={routes.logins()} icon={FiUsers} />
              </>
            )}
            {invitations.length > 0 && (
              <HomeCard
                header={t('ui.navigation.invitations')}
                link={routes.invitations()}
                icon={FiUserCheck}
                badgeCount={invitations.length}
              />
            )}
          </HomeCardContainer>
        </HomeSection>
      </VStack>

      <EditAddressModal address={newAccountAddress} {...addressModalDisclosure} />
    </>
  );
});
