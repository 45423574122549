import type { AdStep } from '@/store/ad/types';

export const CommonStepKeys = {
  platform: 'platform',
  category: 'category',
  edit: 'edit',
  review: 'review',
  payment: 'payment',
} as const;

export const CommonSteps: AdStep[] = [
  {
    key: CommonStepKeys.platform,
    isHidden: true,
    titleResourceKey: 'ad.steps.platform.title',
    introResourceKey: 'ad.steps.platform.description',
    showPreview: false,
    showPriceSummary: false,
    showPriceSummaryTotal: false,
    items: [
      {
        type: 'Component',
        componentName: 'PlatformSelector',
        sortOrder: 1,
      },
    ],
    prevNavigationType: 'Disabled',
    nextNavigationType: 'Enabled',
  },
  {
    key: CommonStepKeys.category,
    isHidden: true,
    titleResourceKey: 'ad.steps.category.title',
    showPreview: false,
    showPriceSummary: false,
    showPriceSummaryTotal: false,
    items: [
      {
        type: 'Component',
        componentName: 'CategorySelector',
        sortOrder: 1,
      },
    ],
    prevNavigationType: 'Unsaved',
    nextNavigationType: 'Enabled',
  },
];

export const NoPaymentProvider = '_none';
