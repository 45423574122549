import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, chakra, Flex, Heading } from '@chakra-ui/react';
import _isEqual from 'lodash/isEqual';
import { accountApi, createMultipartRequestBody, PostV1AccountPrintAdvertPreviewParams } from '@netiva/classifieds-api';
import { useDebounce, usePrevious } from '@netiva/classifieds-common';
import { Loader } from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';
import { useAd, useAdAttributes, useAdParams, useAdSteps } from '@/pages/Ad/hooks';
import { getAttributeFormDataValues } from '@/pages/Ad/utils';

export const EInseratPreview: FC = () => {
  const { t } = useTranslation();
  const currentRequestRef = useRef<{ abort: () => void }>();

  const { currentStep } = useAdSteps();
  const { dataObjectId } = useAdParams();
  const { uploadedFiles, isLoading: isLoadingDataObject } = useAd();
  const { attributes, isLoading: isLoadingAttributes } = useAdAttributes();
  const { formData, images, preview, previewContext } = useAppSelector((state) => state.ad);
  const [errorMessage, setErrorMessage] = useState('');
  const [getPreview, { isLoading }] = accountApi.useGetPrintAdvertPreview();

  const requestBodyJson = useMemo(() => {
    if (Object.keys(formData).length && currentStep?.showPreview) {
      return {
        dataObjectId,
        values: getAttributeFormDataValues(attributes, formData),
        eInseratContext: previewContext,
        image: images[0],
      };
    }
    return null;
  }, [attributes, currentStep?.showPreview, dataObjectId, formData, images, previewContext]);

  const debouncedRequestBodyJson = useDebounce(requestBodyJson, 1000);
  const prevDebouncedRequestBodyJson = usePrevious(debouncedRequestBodyJson);
  const debouncedFiles = useDebounce(uploadedFiles, 500);

  const isLoadingData = isLoadingDataObject || isLoadingAttributes;

  // if ad form data entry or image is updated, fetch the preview
  useEffect(() => {
    if (
      isLoadingData ||
      !debouncedRequestBodyJson ||
      // perform deep comparison to prevent unnecessary API calls
      _isEqual(debouncedRequestBodyJson, prevDebouncedRequestBodyJson)
    ) {
      return;
    }

    // cancel pending request
    if (currentRequestRef.current) {
      currentRequestRef.current.abort();
    }
    setErrorMessage('');
    const params: PostV1AccountPrintAdvertPreviewParams = {
      body: createMultipartRequestBody((formData) => {
        if (debouncedFiles[0]) {
          formData.append('imageFile', debouncedFiles[0].file);
        }
      }, requestBodyJson),
    };
    const request = getPreview(params);
    currentRequestRef.current = request;
    request
      .unwrap()
      .then(() => {
        currentRequestRef.current = undefined;
      })
      .catch((error) => {
        if (error.name !== 'AbortError') {
          setErrorMessage(t('ad.preview.error'));
          console.error(error.message, error);
        }
      });
  }, [
    debouncedFiles,
    debouncedRequestBodyJson,
    getPreview,
    isLoadingData,
    prevDebouncedRequestBodyJson,
    requestBodyJson,
    t,
  ]);

  if (errorMessage !== '') {
    return <Alert status="warning">{errorMessage}</Alert>;
  }

  return (
    <Flex flexDir="column" align="flex-start" marginBottom={4}>
      <Heading as="h3" size="sm">
        {t('preview.title')}
      </Heading>
      <Box position="relative" minW="100px" minH="100px">
        <Loader isLoading={isLoading} size="lg" />
        {preview && (
          <chakra.img src={preview.dataUri} alt={t('preview.title')} maxWidth="100%" boxShadow="default" mt={2} />
        )}
      </Box>
    </Flex>
  );
};
