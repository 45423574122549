import { Fragment, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';

import {
  DescriptionList,
  DescriptionListItem,
  DescriptionListProps,
  DescriptionListTerm,
} from '@netiva/classifieds-ui';

import { useAppSelector } from '@/store';

export type PrintPriceSummaryProps = {
  showMultipleIssues?: boolean;
};

const PriceList = (props: DescriptionListProps) => <DescriptionList variant="alignRight" {...props} />;

export const PrintPriceSummary: FC<PrintPriceSummaryProps> = memo(function Price({
  showMultipleIssues,
}: PrintPriceSummaryProps) {
  const { t } = useTranslation();
  const { price, selectedIssueDates } = useAppSelector((state) => state.ad);
  const issueCount = selectedIssueDates.length || 1;

  if (!price) return null;

  const netAmount = price.amount;
  const vatAmount = price.vatAmount;
  const grossAmount = price.grossAmount;
  const roundingAmount = grossAmount - (netAmount + vatAmount);

  return (
    <Box marginBottom={4}>
      <PriceList bgColor="transparent" marginBottom={4}>
        {price.positions.map((p, i) => (
          <Fragment key={i}>
            <DescriptionListTerm>{p.description}</DescriptionListTerm>
            <DescriptionListItem>
              {p.currency} {p.amount.toFixed(2)}
            </DescriptionListItem>
            {p.isBasedOnHeight && (
              <>
                <DescriptionListTerm variant="small">{t('price.print.height')}</DescriptionListTerm>
                <DescriptionListItem variant="small">{price.advertHeight}mm</DescriptionListItem>
              </>
            )}
          </Fragment>
        ))}
      </PriceList>
      <PriceList marginBottom={4}>
        <DescriptionListTerm>{t('price.print.netTotalFormat', { count: 1 })}</DescriptionListTerm>
        <DescriptionListItem>
          {price.currency} {netAmount.toFixed(2)}
        </DescriptionListItem>
        <DescriptionListTerm variant="small">
          {t('price.vatFormat', { percent: price.vatPercentage.toFixed(1) })}
        </DescriptionListTerm>
        <DescriptionListItem variant="small">
          {price.currency} {vatAmount.toFixed(2)}
        </DescriptionListItem>
        <DescriptionListTerm variant="small">{t('price.roundingDifference')}</DescriptionListTerm>
        <DescriptionListItem variant="small">
          {price.currency} {roundingAmount.toFixed(2)}
        </DescriptionListItem>
        <DescriptionListTerm>{t('price.print.grossTotalFormat', { count: 1 })}</DescriptionListTerm>
        <DescriptionListItem>
          {price.currency} {grossAmount.toFixed(2)}
        </DescriptionListItem>
        {showMultipleIssues && issueCount > 1 && (
          <>
            <DescriptionListTerm>{t('price.print.grossTotalFormat', { count: issueCount })}</DescriptionListTerm>
            <DescriptionListItem>
              {price.currency} {(grossAmount * issueCount).toFixed(2)}
            </DescriptionListItem>
          </>
        )}
      </PriceList>
    </Box>
  );
});
